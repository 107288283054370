@import "./variables";

* {
  outline: none !important;
  padding: 0;
  margin: 0;
}

html {
  font-size: 16px;
  line-height: 164%;
  font-weight: 500;
  color: $color-text;

  overflow-x: hidden;
}

body {
  height: 100vh;

  overflow-x: hidden;
}

a {
  display: inline-flex;

  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  color: $color-text;

  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

img {
  max-width: 100%;
  height: auto;
}

input,
textarea,
select {
  width: 100%;
  box-sizing: border-box;

  font-size: 20px;
  line-height: 120%;
  color: $color-text;

  border: none;
}

button {
  position: relative;

  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  line-height: 164%;
  font-weight: 500;
  color: $color-text;

  background: none;
  border: none;

  cursor: pointer;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
