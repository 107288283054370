.page {
  $root: &;

  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
  margin-bottom: 40px;

  &__title {
    margin-bottom: 0 !important;
    font-weight: 700 !important;
    font-size: 40px !important;
    line-height: 40px !important;
    text-transform: uppercase !important;
    font-family: "Gibson", sans-serif !important;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;

    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      gap: 20px;
    }
  }

  &__form {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    &-header {
      flex: 0 0 calc(50% - 10px);
      flex-direction: row;
      gap: 27px;
    }

    &-field-wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 10px;
    }

    &-field {
      display: flex;
      width: 100%;

      &--checkbox {
        flex-direction: column;
        align-items: center;
        width: auto;
        text-align: center;
      }

      &-grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 10px;

        &.columns-3 {
          grid-template-columns: repeat(3, 1fr);
        }

        &.columns-4 {
          grid-template-columns: repeat(4, 1fr);
        }
      }

      &-header {
        position: relative;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin: 0 !important;

        &.localized {
          padding-left: 24px;

          &::before {
            content: '';

            position: absolute;
            top: 0;
            left: 0;

            width: 20px;
            height: 24px;

            background-image: url('/assets/images/multi-language.svg');
            background-position: 0 0;
            background-repeat: no-repeat;
          }
        }
      }

      &-inner {
        width: 100%;

        &.in-grid {
          margin: 0;
        }

        #{$root} {
          &__field {
            width: 100%;
          }
        }
      }

      &-title {
        margin-bottom: 5px;

        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
      }
    }
  }

  &__field {
    width: 100%;

    &-input-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      gap: 12px;

      .mat-mdc-icon-button {
        height: 40px !important;
        width: 40px !important;
        margin-top: -20px;
        margin-right: -10px;

        .mar-icon {
          height: 24px !important;
          width: 24px !important;
        }
      }
    }
  }

  &__table {
    &-container {
      width: 100%;
      max-height: 65vh;
      overflow: auto;
    }
  }
}


.ellipsis-text__container {
  width: fit-content;
  height: fit-content;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.difficulty-wrap {
  display: flex;
  align-items: center;
  position: relative;
  left: -6px;
  top: -3px;
  margin-bottom: -8px;
}

.image-preview-05 {
  display: block;
  width: 50%;
}

.text-center {
  text-align: center;

  .mat-mdc-tooltip-surface {
    text-align: center !important;
  }
}

.w-full {
  width: 100%;
}
