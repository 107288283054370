@use "@angular/material" as mat;
@include mat.all-component-typographies();
@include mat.core();
@import "bootstrap/scss/mixins";

$primary: mat.m2-define-palette(mat.$m2-gray-palette, 900);
$accent: mat.m2-define-palette(mat.$m2-gray-palette, 400);
$warn: mat.m2-define-palette(mat.$m2-red-palette, 500);
$theme: mat.m2-define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn
  ),
));

@include mat.core-theme($theme);

mat.$theme-ignore-duplication-warnings: true;

@include mat.all-component-themes($theme);

$color-text: #1d1d1d;
