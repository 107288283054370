@font-face {
  font-family: "Proxima Nova Black";
  src: url("../../../assets/fonts/proxima-nova/ProximaNova-Black.eot");
  src: url("../../../assets/fonts/proxima-nova/ProximaNova-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/fonts/proxima-nova/ProximaNova-Black.woff")
      format("woff"),
    url("../../../assets/fonts/proxima-nova/ProximaNova-Black.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gibson";
  src: local("Gibson Light"), local("Gibson-Light"),
    url("../../../assets/fonts/gibson/Gibson-Book.otf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gibson";
  src: local("Gibson Medium"), local("Gibson-Medium"),
    url("../../../assets/fonts/gibson/Gibson-Medium.otf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gibson";
  font-style: normal;
  font-weight: 700;
  src: local("Gibson Bold"), local("Gibson-Bold"),
    url("../../../assets/fonts/gibson/Gibson-Bold.otf") format("truetype");
}