@use '@ng-matero/extensions' as mtx;
@import 'assets/styles/main';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';

@include mtx.all-component-themes($theme);

/* You can add global styles to this file, and also import other style files */

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
  background: #000;
  opacity: 0.2 !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.chip-box.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.chips .cdk-drop-list-dragging {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-calendar-previous-button::after,
.mat-calendar-next-button::after {
  margin: 8px !important;
}
