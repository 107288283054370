.mat-row--empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px 0;
}

mat-form-field {
  width: 30vw;
}

.mat-column-actions {
  button:not(:last-child) {
    margin-right: 10px;
  }
}

mat-header-row,
.mat-paginator-sticky {
  background-color: #ededed !important;
}

.mat-paginator-sticky {
  bottom: 0;
  position: sticky;
  z-index: 10;
}

.mdc-icon-button {
  width: 27px !important;
  height: 27px !important;
  line-height: 27px !important;
  padding: 1px !important;

  .mat-icon {
    font-size: 15px;
    width: 15px;
    height: 15px;
    line-height: 15px;
  }
}
